<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Edit Prevalida</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                                <template v-if="pageReady">
                                    <div class="grid">
                                        <div class="col-12">
                                            <form ref="observer"  tag="form" @submit.prevent="submitForm()">
                                                <!--[form-content-start]-->
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Trackingnum 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrltrackingnum" v-model.trim="formData.trackingnum"  label="Trackingnum" type="text" placeholder="Enter Trackingnum"      
                                                                class=" w-full" :class="getErrorClass('trackingnum')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('trackingnum')" class="p-error">{{ getFieldError('trackingnum') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Carrier 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlcarrier" v-model.trim="formData.carrier"  label="Carrier" type="number" placeholder="Enter Carrier"   step="any"    
                                                                class=" w-full" :class="getErrorClass('carrier')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('carrier')" class="p-error">{{ getFieldError('carrier') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Loadsheet 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlloadsheet" v-model.trim="formData.loadsheet"  label="Loadsheet" type="text" placeholder="Enter Loadsheet"      
                                                                class=" w-full" :class="getErrorClass('loadsheet')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('loadsheet')" class="p-error">{{ getFieldError('loadsheet') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Factura 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlfactura" v-model.trim="formData.factura"  label="Factura" type="text" placeholder="Enter Factura"      
                                                                class=" w-full" :class="getErrorClass('factura')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('factura')" class="p-error">{{ getFieldError('factura') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Controlno 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlcontrolno" v-model.trim="formData.controlno"  label="Controlno" type="text" placeholder="Enter Controlno"      
                                                                class=" w-full" :class="getErrorClass('controlno')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('controlno')" class="p-error">{{ getFieldError('controlno') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Po 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlpo" v-model.trim="formData.po"  label="Po" type="text" placeholder="Enter Po"      
                                                                class=" w-full" :class="getErrorClass('po')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('po')" class="p-error">{{ getFieldError('po') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Problempo 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlproblempo" v-model.trim="formData.problempo"  label="Problempo" type="number" placeholder="Enter Problempo"   step="any"    
                                                                class=" w-full" :class="getErrorClass('problempo')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('problempo')" class="p-error">{{ getFieldError('problempo') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Item 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlitem" v-model.trim="formData.item"  label="Item" type="text" placeholder="Enter Item"      
                                                                class=" w-full" :class="getErrorClass('item')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('item')" class="p-error">{{ getFieldError('item') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Cantidad 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlcantidad" v-model.trim="formData.cantidad"  label="Cantidad" type="number" placeholder="Enter Cantidad"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('cantidad')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('cantidad')" class="p-error">{{ getFieldError('cantidad') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Unidadmedidacomercial 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlunidadmedidacomercial" v-model.trim="formData.unidadmedidacomercial"  label="Unidadmedidacomercial" type="text" placeholder="Enter Unidadmedidacomercial"      
                                                                class=" w-full" :class="getErrorClass('unidadmedidacomercial')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('unidadmedidacomercial')" class="p-error">{{ getFieldError('unidadmedidacomercial') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Cantidadtarifa 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlcantidadtarifa" v-model.trim="formData.cantidadtarifa"  label="Cantidadtarifa" type="number" placeholder="Enter Cantidadtarifa"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('cantidadtarifa')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('cantidadtarifa')" class="p-error">{{ getFieldError('cantidadtarifa') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Unidadmedidatarifa 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlunidadmedidatarifa" v-model.trim="formData.unidadmedidatarifa"  label="Unidadmedidatarifa" type="text" placeholder="Enter Unidadmedidatarifa"      
                                                                class=" w-full" :class="getErrorClass('unidadmedidatarifa')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('unidadmedidatarifa')" class="p-error">{{ getFieldError('unidadmedidatarifa') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Kgs 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlkgs" v-model.trim="formData.kgs"  label="Kgs" type="text" placeholder="Enter Kgs"      
                                                                class=" w-full" :class="getErrorClass('kgs')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('kgs')" class="p-error">{{ getFieldError('kgs') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Bultos 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlbultos" v-model.trim="formData.bultos"  label="Bultos" type="number" placeholder="Enter Bultos"   step="any"    
                                                                class=" w-full" :class="getErrorClass('bultos')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('bultos')" class="p-error">{{ getFieldError('bultos') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Modelo 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlmodelo" v-model.trim="formData.modelo"  label="Modelo" type="text" placeholder="Enter Modelo"      
                                                                class=" w-full" :class="getErrorClass('modelo')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('modelo')" class="p-error">{{ getFieldError('modelo') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Numparte 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlnumparte" v-model.trim="formData.numparte"  label="Numparte" type="text" placeholder="Enter Numparte"      
                                                                class=" w-full" :class="getErrorClass('numparte')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('numparte')" class="p-error">{{ getFieldError('numparte') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Numserie 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlnumserie" v-model.trim="formData.numserie"  label="Numserie" type="text" placeholder="Enter Numserie"      
                                                                class=" w-full" :class="getErrorClass('numserie')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('numserie')" class="p-error">{{ getFieldError('numserie') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Mca 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlmca" v-model.trim="formData.mca"  label="Mca" type="text" placeholder="Enter Mca"      
                                                                class=" w-full" :class="getErrorClass('mca')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('mca')" class="p-error">{{ getFieldError('mca') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Descripmex 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrldescripmex" v-model.trim="formData.descripmex"  label="Descripmex" type="text" placeholder="Enter Descripmex"      
                                                                class=" w-full" :class="getErrorClass('descripmex')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('descripmex')" class="p-error">{{ getFieldError('descripmex') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Descripusa 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrldescripusa" v-model.trim="formData.descripusa"  label="Descripusa" type="text" placeholder="Enter Descripusa"      
                                                                class=" w-full" :class="getErrorClass('descripusa')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('descripusa')" class="p-error">{{ getFieldError('descripusa') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Fraccion 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlfraccion" v-model.trim="formData.fraccion"  label="Fraccion" type="text" placeholder="Enter Fraccion"      
                                                                class=" w-full" :class="getErrorClass('fraccion')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('fraccion')" class="p-error">{{ getFieldError('fraccion') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Fraccionus 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlfraccionus" v-model.trim="formData.fraccionus"  label="Fraccionus" type="text" placeholder="Enter Fraccionus"      
                                                                class=" w-full" :class="getErrorClass('fraccionus')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('fraccionus')" class="p-error">{{ getFieldError('fraccionus') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Costo 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlcosto" v-model.trim="formData.costo"  label="Costo" type="number" placeholder="Enter Costo"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('costo')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('costo')" class="p-error">{{ getFieldError('costo') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Valorunitario 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlvalorunitario" v-model.trim="formData.valorunitario"  label="Valorunitario" type="number" placeholder="Enter Valorunitario"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('valorunitario')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('valorunitario')" class="p-error">{{ getFieldError('valorunitario') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Valordlls 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlvalordlls" v-model.trim="formData.valordlls"  label="Valordlls" type="number" placeholder="Enter Valordlls"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('valordlls')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('valordlls')" class="p-error">{{ getFieldError('valordlls') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Va 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlva" v-model.trim="formData.va"  label="Va" type="number" placeholder="Enter Va"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('va')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('va')" class="p-error">{{ getFieldError('va') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Valortotal 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlvalortotal" v-model.trim="formData.valortotal"  label="Valortotal" type="number" placeholder="Enter Valortotal"   step="0.1"    
                                                                class=" w-full" :class="getErrorClass('valortotal')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('valortotal')" class="p-error">{{ getFieldError('valortotal') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Origin 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlorigin" v-model.trim="formData.origin"  label="Origin" type="text" placeholder="Enter Origin"      
                                                                class=" w-full" :class="getErrorClass('origin')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('origin')" class="p-error">{{ getFieldError('origin') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Notas 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlnotas" v-model.trim="formData.notas"  label="Notas" type="text" placeholder="Enter Notas"      
                                                                class=" w-full" :class="getErrorClass('notas')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('notas')" class="p-error">{{ getFieldError('notas') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Whomake 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlwhomake" v-model.trim="formData.whomake"  label="Whomake" type="text" placeholder="Enter Whomake"      
                                                                class=" w-full" :class="getErrorClass('whomake')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('whomake')" class="p-error">{{ getFieldError('whomake') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Admin 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrladmin" v-model.trim="formData.admin"  label="Admin" type="text" placeholder="Enter Admin"      
                                                                class=" w-full" :class="getErrorClass('admin')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('admin')" class="p-error">{{ getFieldError('admin') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Moddate 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <Calendar :showButtonBar="true" :showTime="true" dateFormat="yy-mm-dd" hourFormat="24" v-model="formData.moddate" :showIcon="true" class="w-full" :class="getErrorClass('moddate')"     mask="yy-MM-DD HH:mm"     />
                                                                <small v-if="isFieldValid('moddate')" class="p-error">{{ getFieldError('moddate') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Reflink 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlreflink" v-model.trim="formData.reflink"  label="Reflink" type="text" placeholder="Enter Reflink"      
                                                                class=" w-full" :class="getErrorClass('reflink')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('reflink')" class="p-error">{{ getFieldError('reflink') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Vendor 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlvendor" v-model.trim="formData.vendor"  label="Vendor" type="text" placeholder="Enter Vendor"      
                                                                class=" w-full" :class="getErrorClass('vendor')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('vendor')" class="p-error">{{ getFieldError('vendor') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Cont 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlcont" v-model.trim="formData.cont"  label="Cont" type="number" placeholder="Enter Cont"   step="any"    
                                                                class=" w-full" :class="getErrorClass('cont')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('cont')" class="p-error">{{ getFieldError('cont') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Count 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlcount" v-model.trim="formData.count"  label="Count" type="text" placeholder="Enter Count"      
                                                                class=" w-full" :class="getErrorClass('count')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('count')" class="p-error">{{ getFieldError('count') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Observaciones 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlobservaciones" v-model.trim="formData.observaciones"  label="Observaciones" type="text" placeholder="Enter Observaciones"      
                                                                class=" w-full" :class="getErrorClass('observaciones')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('observaciones')" class="p-error">{{ getFieldError('observaciones') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Inbond 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlinbond" v-model.trim="formData.inbond"  label="Inbond" type="text" placeholder="Enter Inbond"      
                                                                class=" w-full" :class="getErrorClass('inbond')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('inbond')" class="p-error">{{ getFieldError('inbond') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Sloads 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlsloads" v-model.trim="formData.sloads"  label="Sloads" type="text" placeholder="Enter Sloads"      
                                                                class=" w-full" :class="getErrorClass('sloads')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('sloads')" class="p-error">{{ getFieldError('sloads') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Pic 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <div class="mb-3">
                                                                    <Uploader :class="getErrorClass('pic')" :auto="true" :fileLimit="1" :maxFileSize="3000000" accept=".jpg,.png,.gif,.jpeg" :multiple="false" style="width:100%" label="Choose files or drop files here" upload-path="fileuploader/upload/pic" v-model="formData.pic"></Uploader>
                                                                </div>
                                                                <small v-if="isFieldValid('pic')" class="p-error">{{ getFieldError('pic') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Inwarehouse Flag 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlinwarehouse_flag" v-model.trim="formData.inwarehouse_flag"  label="Inwarehouse Flag" type="text" placeholder="Enter Inwarehouse Flag"      
                                                                class=" w-full" :class="getErrorClass('inwarehouse_flag')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('inwarehouse_flag')" class="p-error">{{ getFieldError('inwarehouse_flag') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Vref 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlvref" v-model.trim="formData.vref"  label="Vref" type="text" placeholder="Enter Vref"      
                                                                class=" w-full" :class="getErrorClass('vref')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('vref')" class="p-error">{{ getFieldError('vref') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Vtracking 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlvtracking" v-model.trim="formData.vtracking"  label="Vtracking" type="text" placeholder="Enter Vtracking"      
                                                                class=" w-full" :class="getErrorClass('vtracking')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('vtracking')" class="p-error">{{ getFieldError('vtracking') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Vinvoice 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlvinvoice" v-model.trim="formData.vinvoice"  label="Vinvoice" type="text" placeholder="Enter Vinvoice"      
                                                                class=" w-full" :class="getErrorClass('vinvoice')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('vinvoice')" class="p-error">{{ getFieldError('vinvoice') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Plantid 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlplantid" v-model.trim="formData.plantid"  label="Plantid" type="text" placeholder="Enter Plantid"      
                                                                class=" w-full" :class="getErrorClass('plantid')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('plantid')" class="p-error">{{ getFieldError('plantid') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Customscode 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrlcustomscode" v-model.trim="formData.customscode"  label="Customscode" type="text" placeholder="Enter Customscode"      
                                                                class=" w-full" :class="getErrorClass('customscode')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('customscode')" class="p-error">{{ getFieldError('customscode') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Listo 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <InputText ref="ctrllisto" v-model.trim="formData.listo"  label="Listo" type="number" placeholder="Enter Listo"   step="any"    
                                                                class=" w-full" :class="getErrorClass('listo')">
                                                                </InputText>
                                                                <small v-if="isFieldValid('listo')" class="p-error">{{ getFieldError('listo') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--[form-content-end]-->
                                                <div v-if="showSubmitButton" class="text-center my-3">
                                                    <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="loading">
                                    <div class="p-3 text-center">
                                        <ProgressSpinner style="width:50px;height:50px" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, numeric, } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useEditPage } from '@/composables/editpage.js';
	const props = defineProps({
		id: [String, Number],
		pageName: {
			type: String,
			default: 'prevalida',
		},
		routeName: {
			type: String,
			default: 'prevalidaedit',
		},
		pagePath: {
			type : String,
			default : 'prevalida/edit',
		},
		apiPath: {
			type: String,
			default: 'prevalida/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		msgAfterUpdate: {
			type: String,
			default: "Record updated successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	const formDefaultValues = {
		trackingnum: "", 
		carrier: "", 
		loadsheet: "", 
		factura: "", 
		controlno: "", 
		po: "", 
		problempo: "1", 
		item: "", 
		cantidad: "0.0000", 
		unidadmedidacomercial: "", 
		cantidadtarifa: "0", 
		unidadmedidatarifa: "", 
		kgs: "", 
		bultos: "0", 
		modelo: "", 
		numparte: "", 
		numserie: "", 
		mca: "", 
		descripmex: "", 
		descripusa: "", 
		fraccion: "", 
		fraccionus: "", 
		costo: "0", 
		valorunitario: "0.00000", 
		valordlls: "0.00", 
		va: "0.00", 
		valortotal: "0.00", 
		origin: "", 
		notas: "", 
		whomake: "steve", 
		admin: "", 
		moddate: "", 
		reflink: "", 
		vendor: "", 
		cont: "0", 
		count: "", 
		observaciones: "", 
		inbond: "", 
		sloads: "", 
		pic: "", 
		inwarehouse_flag: "", 
		vref: "", 
		vtracking: "", 
		vinvoice: "", 
		plantid: "", 
		customscode: "", 
		listo: "", 
	}
	const formData = reactive({ ...formDefaultValues });
	function onFormSubmited(response) {
		app.flashMsg(props.msgAfterUpdate);
		if(props.redirect) app.navigateTo(`/prevalida`);
	}
	const rules = computed(() => {
		return {
			trackingnum: {  },
			carrier: { numeric },
			loadsheet: {  },
			factura: {  },
			controlno: {  },
			po: {  },
			problempo: { numeric },
			item: {  },
			cantidad: { numeric },
			unidadmedidacomercial: {  },
			cantidadtarifa: { numeric },
			unidadmedidatarifa: {  },
			kgs: {  },
			bultos: { numeric },
			modelo: {  },
			numparte: {  },
			numserie: {  },
			mca: {  },
			descripmex: {  },
			descripusa: {  },
			fraccion: {  },
			fraccionus: {  },
			costo: { numeric },
			valorunitario: { numeric },
			valordlls: { numeric },
			va: { numeric },
			valortotal: { numeric },
			origin: {  },
			notas: {  },
			whomake: {  },
			admin: {  },
			moddate: {  },
			reflink: {  },
			vendor: {  },
			cont: { numeric },
			count: {  },
			observaciones: {  },
			inbond: {  },
			sloads: {  },
			pic: {  },
			inwarehouse_flag: {  },
			vref: {  },
			vtracking: {  },
			vinvoice: {  },
			plantid: {  },
			customscode: {  },
			listo: { numeric }
		}
	});
	const v$ = useVuelidate(rules, formData); //form validation
	const page = useEditPage(props, formData, v$, onFormSubmited);
	//page state
	const {
		submitted, // form api submitted state - Boolean
		saving, // form api submiting state - Boolean
		loading, // form data loading state - Boolean
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page computed propties
	const {
		apiUrl, // page current api path
		currentRecord, // current page record  - Object
	} = page.computedProps
	//page methods
	const { 
		load, // get editable form data from api
		submitForm, // submit form data to api
		getErrorClass, // return error class if field is invalid- args(fieldname)
		getFieldError, //  get validation error message - args(fieldname)
		isFieldValid, // check if field is validated - args(fieldname)
		 // map api datasource  to Select options label-value
	} = page.methods;
	onMounted(()=>{
		const pageTitle = "Edit Prevalida";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
